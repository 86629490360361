(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@sentry/browser"));
	else if(typeof define === 'function' && define.amd)
		define(["@sentry/browser"], factory);
	else if(typeof exports === 'object')
		exports["tikSDK"] = factory(require("@sentry/browser"));
	else
		root["tikSDK"] = factory(root["Sentry"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__8__) {
return 